<template>
  <div class="auth-wrapper auth-v2">
    <span class="languageDropdown">
      <locale />
    </span>

    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ AppName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            <!-- Adventure starts here 🚀 -->
            {{ $t("register.header") }}🚀
          </b-card-title>
          <b-card-text class="mb-2">
            <!-- {{ $t("register.subtitle") }} -->
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- username -->
              <b-form-group
                :label="$t('nameLabel')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="$t('emailLabel')"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                :label="$t('password')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t("policy.agree") }}
                  <b-link>{{ $t("policy.terms") }}</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                v-show="!isLoading"
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                <!-- Sign up -->
                {{ $t("register.actionBtn") }}
              </b-button>

              <b-button v-show="isLoading" block variant="primary" disabled>
                <b-spinner small />
                {{ $t("register.actionBtn") }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t("register.toggleLabel") }}</span>
            <b-link :to="{ name: 'auth-login-v2' }">
              <span>&nbsp;{{ $t("register.toggleBtn") }}</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

          <div class="">
            <google-login-btn />
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// // firebase
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';

import branding from "@/texts/branding.json";

import { emailSignup, googleLogin } from "@/auth/utils";

import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";

import GoogleLoginBtn from "./GoogleLoginBtn";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,

    // validations
    ValidationProvider,
    ValidationObserver,
    Locale,

    // custom
    GoogleLoginBtn,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      AppName: branding.AppName,
      status: "",
      username: "",
      userEmail: "",
      password: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),

      // validation
      required,
      email,

      isLoading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.email_signup();
        }
      });
    },

    email_signup() {
      this.isLoading = true;
      emailSignup(
        this.username,
        this.userEmail,
        this.password,
        this.$ability,
        this.$router,
        this.$toast
      )
        .then((user) => {
          console.log("emailSignup success: ", user);
          this.isLoading = false;
          this.$router.push({ path: "/" });
        })
        .catch((err) => {
          console.log("email signup error: ", err);
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + err.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    google_signin() {
      // invoke auth util function
      googleLogin(this.$ability, this.$router, this.$toast);
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
